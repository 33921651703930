<template>
    <div class="productos__contenido">
        <h2>{{ categoria.nombre }}</h2>
        <div class="heading-grande-25">{{ categoria.nombre }}</div>
        <div class="productos">
            <div v-for="producto in categoria.productos" :key="producto.id" class="producto">
                <h3>{{ producto.nombre }}</h3>
                <picture>
                    <source :srcset="producto.ruta_imagen_avif" type="image/avif">
                    <source :srcset="producto.ruta_imagen_webp" type="image/webp">
                    <source :srcset="producto.ruta_imagen" type="image/png">
                    <img :src="producto.ruta_imagen" :alt="'Imagen de ' + producto.nombre">
                </picture>
                <div v-html="producto.descripcion"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['categoria'],
}
</script>